@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.supercontainer {
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  // border: 1px solid brown;
  width: 90%;
  margin-bottom: 5em;
//   color: #3a3a3a;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.instruction {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.instructions1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: large;
  font-style: normal;
}

.instructions2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 0.4em;
}

.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: 1px solid green;
  margin-top: 4em;
}

.formoutter {
  flex: 1;
  width: 100%;
  // border: 1px solid blue;
  background-color: rgba(145, 234, 145, 0.05);
  border-bottom: 3px solid whitesmoke;
}

.form {
  width: 90%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid orange
}

.columngrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.inputcolumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 30%;
}

.input2column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 47.5%;
}

.input1column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.inputcolumn > span {
    font-weight: 500;
}

.input1column > span {
    font-weight: 500;
}

.input2column > span {
    font-weight: 500;
}

.inputfield {
  width: 100%;
  height: 5em;
  padding: 1em;
  border: 0.1px solid lightgray;
  border-radius: 0.2em;
}

.selectinput {
  width: 100%;
  height: 5em;
//   border: 0.1px solid lightgray;
  border-radius: 0.2em;
}

.dateinput {
  width: 100%;
  height: 5em;
//   border: 0.1px solid lightgray;
  border-radius: 0.2em;
}

.panel {
  background-color: whitesmoke;
}

.selectinput:focus {
  border: 1px solid rgba(145, 234, 145, 0.5);
}

.inputfield:focus {
  border: 1px solid rgba(145, 234, 145, 0.5);
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 2em;
}

.headerblock {
  width: 17px;
  height: 19px;
  background-color: rgba(145, 234, 145, 1);
  margin-right: 0.5em;
}

.headertext {
  font-size: 18px;
  font-weight: 700;
}

.radiocontainer {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3em;
}

.radiorow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  margin-left: auto;
  margin-right: auto;
  width: 12em;
  height: 3em;
  border-radius: 5px;
  background-color: rgba(58, 58, 58, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5em;
  color: white;
  font-weight: 600;
}

.button:hover{
    background-color: white;
    color: rgba(145, 234, 145, 1);
    border: 1px solid rgba(145, 234, 145, 1);
}

.red {
 color: #e81b33;
 padding-left: 0.2em;
}

@media screen and (max-width: 768px) {
  .columngrid {
    flex-direction: column;
  }

  .inputcolumn {
    width: 100%;
    margin-bottom: 1em;
  }

  .input2column {
    width: 100%;
    margin-bottom: 1em;
  }

  .input1column {
    margin-bottom: 1em;
  }
}
