.out-contain {
  width: 20%;
  //   height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background-color: white;
}

.contain {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
}

.sel-contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #8a8a8a;
  border-bottom: 1.5px solid #c0b9b9;
}

.sel-contain > span {
  font-weight: 500;
  font-size: 16px;
  padding: 0.5em 0;
}

.cancel-container-I {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

.item-contain {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #383838;
  border-bottom: 0.1px solid #dcd5d5;
  margin-top: 10px;
}

.item-contain > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18.9px;
  padding: 0.8em 0;
}

.item-contain > img {
  margin-right: 5px;
}

.item-contain:hover {
  background-color: #f4f9f0;
}

// save-quote-success || payment-modal
.save-cont {
  width: 30%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
}

.inner-cont {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-cont {
  background-color: #f4f9f0;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  align-self: flex-end;
  margin-bottom: 2em;
  margin-top: 0.5em;
}

.save-quote-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #383838;
  margin: 1em 0;
}

.payment-text {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #383838;
}

.payment-text-w {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #383838;
}

.payment-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #91ea91;
  margin: 0.5em 0;
}

.save-quote-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #383838;
  text-align: center;
  margin: 0.7em 0;
}

.save-quote-but-green {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #91ea91;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin: 0.4em 0;
  border-radius: 0.3em;
}

.save-quote-but-green > span {
  padding: 0.5em 1.5em;
}

.payment-but-green {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #91ea91;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin: 0.4em 0;
  border-radius: 0.3em;
  min-width: 65%;
}

.payment-but-green > span {
  padding: 0.5em 1.5em;
}

.save-home {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: underline;
  margin-bottom: 2em;
  color: #91ea91;
}

.payment-modal-line {
  width: 100%;
  height: 0.5px;
  background-color: #bfbfbf;
  margin: 0.5em 0;
}

.payment-wallet-bal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.payment-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.success-pay-policy {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0em 0;
}

.policy-txt {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  color: #8a8a8a;
  padding-right: 2px;
}

.policy-no {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: #4d4d4d;
}

.display-pop {
  background-color: white;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  margin: 1em;
}

.display-pop-in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1em;
}

.display-cancel {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.display-pop-txt {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #383838;
  padding: 0.5em;
}

.display-pop-txt-green {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #91ea91;
  padding: 0.5em;
}

.inputcolumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  margin: 0.2em auto;
}

.inputfield {
  width: 100%;
  height: 3.5em;
  padding: 1em;
  border: 0.1px solid #4d4d4d;
  border-radius: 0.3em;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #4d4d4d;
}

.red {
  color: #e81b33;
  padding-left: 0.2em;
}

//more information

.more-container {
  width: 55%;
//   height: 50%;
  display: flex;
  background-color: white;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cancel-container {
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

.cancel-button {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #f6fdf6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}

.instruction {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  text-align: center;
  align-self: center;
  max-width: 80%;
}

.input {
  height: 50%;
  width: 80%;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  margin: 15px 0
}

.send-button {
  width: 60%;
  margin: 0.5em auto 2em auto;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #a8f3a8;
  color: #7a7a7a;
  border-radius: 0.5em;
}

.send-button:hover {
  border: 1px solid #91ea91;
  background-color: white;
  color: #91ea91;
}

.send-text {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .out-contain {
    width: 80%;
  }

  .save-cont {
    width: 90%;
  }

  //more information
  .more-container {
    width: 80%;
    height: 75%;
  }

  .input {
    width: 90%;
  }

  .send-button {
    width: 80%;
  }
}
