.show-it-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.show-circle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 0.2px solid #91ea91;
  z-index: 20;
}

.show-circle-sel {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #91ea91;
  z-index: 20;
}

.show-line {
  width: 100%;
  position: absolute;
  height: 1px;
  background-color: #91ea91;
  top: 50%;
  z-index: 10;
}

.txt {
  font-size: 15px;
  color: #0d600d;
}
