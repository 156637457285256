.title h3{
    font-weight: 500;
    // position: relative;
    z-index: -1;
}

.tablehead {
    // position: relative;
    z-index: -1;
}

.commentStatus {
  color: green;
font-size:xx-large
}

.buttoncontainer:hover {
  background-color: var(--ltn__paragraph-color);
}

.commentStatusFalse {
  color: red;
  font-size:xx-large
}

body.modal-open {
  overflow: hidden;
}

.clickableelement {
  cursor: pointer;
}

.lineClamp{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.myDiv {
    background-color: var(--section-bg-2);
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease; /* Add a smooth transition */
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* Define the hover effect */
    &:hover {
      background-color: green;
    }
  }

.myDivWrong {
    background-color: var(--section-bg-2);
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease; /* Add a smooth transition */
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* Define the hover effect */
    &:hover {
      background-color: red;
    }
  }

  .overlay {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .divContainer{
    display: flex;
    width: '100%';
    align-items: "left";
    justify-content: "space-between";
    margin-top: 30;
    @media screen and (min-width: 368px  ) {
      width: fit-content;
      padding: 0;
    }
  }

  .divSecond{
    margin-left: "30%";
    align-self: 'center'
  }

  .mainContainer{
    align-items: 'center'; 
    justify-content: 'center'; 
    padding: 10;

    @media screen and (max-width: 768px ) {
      padding: 0;
      width: 'auto';
    }
    @media screen and (min-width: 368px  ) {
      width: fit-content;
      padding: auto;
    }
      
    }

  .boldHeader{
    font-size: 30;
  }

.player{
  width:'5%'; 
  height: 'auto'; 
  margin-left: 10;

  @media screen and (min-width: 328px ) {
    width: '100%';
    margin-left: 0;
  }
}