.tabContent{
    width: 100%;
}

.container {
    width: 30em;
    height: 16em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    // background: linear-gradient(135deg, rgb(0, 255, 214) 0%, rgb(8, 226, 96) 100%);
    // background-color: rgb(223, 218, 218);
    // border: 1px solid #009900;
    background-color: #ecf4f4;
    // border-radius: 0.2em;
    // background: radial-gradient(#b0e633, #53ef7d);
    // border-width: 0.5em;
    // border-color: 
}

.headerCancel {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: large;
    font-family:monospace;
    margin-bottom: 1em;
    margin-top: 0.8em;
    margin-left: 1.5em;
    color: black;
    font-weight: bold;
    align-self: center;
}

.body {
    width: 85%;
    margin-bottom: 1em;
    margin-left: 1.5em;
    color: black;
    font-size: large;
    font-family:monospace;
    font-weight: 300;
    align-self: center;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin-left: 1.5em;
    color: black;
    margin-top: 0.5em;
}

.icon {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.imgContainer {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.makePayment {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    height: 100%;
    align-self: center;
    background-color: white;
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    align-self: center;
    margin-bottom: 0.8em;
}

.cancel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: center;
}

.input {
    width: 100%;
    height: 3em;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px;
    color: '#3A3A3A';
}

input:focus {
    outline: none;
    border-color: #dceedd; /* Change the color to your desired focus color */
    box-shadow: 0 0 5px rgba(196, 230, 185, 0.5); /* Add a subtle box shadow on focus */
  }

.inputContainer{
    width: 30%;
    height: 5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.cardArea{
    background-color: var(--section-bg-1);
    padding: 20px 30px;
    display: flex;
    align-items: center;
    width: auto;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    height: 2.5em;
}

.cardArea p{
    margin: 0;
}

.cardArea:hover{
    transform: scale(1.05);
}


@media screen and (max-width: 768px) {
   .container {
    width: 20em;
    height: 17em;
   }
  }

@media screen and (max-width: 378px) {
   .container {
    width: 15em;
    height: 20em;
   }
  }
