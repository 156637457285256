.upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5em;
}

.upload-container-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.upload-but {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #91ea91;
  border: 1px solid #383838;
  border-radius: 0.4em;
  margin-right: 1em;
}

.upload-but > input {
  display: none;
  width: 100%;
  height: 100%;
}

.upload-but > span {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 1em;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis
}

.doc-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
}

.doc-cont-out {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
  max-width: 65%;
  width: 65%;
  flex-wrap: wrap;
}

.doc-cont > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #3292eb;
}

.upload-container > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #8A8A8A;
}
