.img_container {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}

.image{
    filter: brightness(50%);
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
}

.lg_text{
    font-size: 48px;
    color: white;
    font-weight: 700;
    line-height: 72px;
}

.sm_text{
    font-weight: 600;;
    font-size: 24px;
    color: white;
    line-height: 36px;
    margin-bottom: 20px;
}

.apply_btn{
    background-color: #4AB34A;
    border: 1px solid #4AB34A;
    color: white;
    width: 30%;
    margin: auto;
    border-radius: 20px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.form_card_layout{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
}

.card_list{
    display: grid;
    gap: 40px;
    width: 50%;
}

.white_card{
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
}

.header{
    font-weight: 700;
    font-size: 28px;
    color: #0D600D;
}

.subtitle{
    font-weight: 400;
    font-size: 20px;
    color: black;
}

.light_green_card{
    background-color: #F6FDF6;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
}

ul li::marker{
    color: #0D600D;
}

ul li{
    color: black;
}

.rel_view{
    position: relative;
    height: 100%;
    margin-top: 50%;
}

.basic_green_card{
    background-color: #F6FDF6;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    top: -50%;
    margin-left: 30px;
    margin-right: 30px;
}

.form_layout{
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #BFBFBF;
    height: fit-content;
}
.form_header{
    font-size: 24px;
    font-weight: 500;
    line-height: 48px;
    color: black;

}

.input_divs{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 15px;
}

.input_divs input::placeholder::after{
    content: "*";
    color: red;
}

.submit_btn{
    background-color: #4AB34A;
    border: 1px solid #4AB34A;
    color: white;
    border-radius: 5px;
    height: 50px;
    width: 50%;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    align-self: center;
}

.dropdown_view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.dropdown_view img{
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
}

.list_border{
    border: 1px solid #BFBFBF;
    padding: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.list_container{
    background-color: #F6FDF6;
    padding: 20px;
}

.list_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon_style{
    background-color: white;
    border: 1px solid #0D600D;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}

// .icon_style img{
//     cursor: pointer;
// }

.list_title{
    color: #0D600D;
    font-weight: 500;
    font-size: 24px;
}

.displayed_vendors{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.displayed_vendors span{
    font-weight: 500;
    font-size: 16px;
    color: black;
}

@media (max-width: 768px) {
    .form_card_layout{
        flex-direction: column;
    }

    .image{
        min-height: 800px;
    }

    .apply_btn{
        width: 100%;
    }

    .card_list{
        width: 100%;
    }

    .overlapped_image{
        min-height: 500px;
    }

    .form_layout{
        margin-top: 30px;
        width: 100%;
    }

    .rel_view{
        height: auto;
        margin-top: 160%;
    }

    .basic_green_card{
        top: -100%;
    }
}