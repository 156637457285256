.logoImage{
    margin-right: 10px;
}

.logoImage img{
    height: 40px;
    width: 100%;
    // background-color: red;
    object-fit: contain;
}

@media (max-width) {
    
}