.container{
    display: flex;
}

.container input{
    width: 5ch !important;
    height: 5ch !important;
    padding: 0 !important;
    text-align: center;
    margin-bottom: 0px !important;
}

.container input:not(:first-child){
    margin-left: 15px
}