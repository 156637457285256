.tab {
    cursor: pointer;
    width: 6em;
  }
  
.selected {
    background-color: rgba(145, 234, 145, 0.8) !important;
    color: var(--white) !important;
    font-weight: bolder;
    font-style: italic;
  }