.container {
  flex: 1;
  //padding: 10px 0;
}

.col {
  padding: 15px;
}

.table {
  width: 100%;
}

.table th {
  padding: 12px 0px;
}

.headerRow {
  border-bottom: 1px solid white;
}

.balanceRow {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.table tr > td:last-child {
  text-align: right;
}

.table td {
  padding: 12px 0px;
  font-size: 14px;
}

.summary {
  background-color: var(--border-color-1);
  padding: 15px;
  border-radius: 8px;
}

.info {
  height: 100%;
}

.title {
  align-self: start;
}

.title h4 {
  text-align: center;
}

.propertyTable {
  width: 100%;
  flex: 0.2;
}

.propertyTable td {
  padding: 3px 0px;
  font-size: 14px;
}

.propertyTable tr > td:last-child {
  text-align: right;
}

.propertyImage {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.propertyImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

