.content{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image{
   width: 60%;
   padding: 2%;
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text{
    text-align: center;
}

.text h4{
    font-size: 18px;
}

.text p{
    font-size: 14px;
}

.btn button{
    color: var(--wrong);
    border-bottom: 1px solid var(--wrong);
    background-color: transparent;
    opacity: 0.7;
}

.btn button:hover{
    opacity: 1;
}