.container{
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}

.first_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.text_and_button{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}

.imageContainer{
    position: relative;
    width: 80%;
}

.header{
    color: #383838;
    font-weight: 700;
    font-size: 36px;
}

.exploreBtn{
    width: 40%;
}

.subtitle{
font-size: 24px;
font-weight: 500;
line-height: 48px;
text-align: left;
color: #383838;
}

.image{
    position: absolute;
    width: 100%;
    opacity: 0;
    animation: fadeAnimation 15s infinite;
    top: -100px;
}

.image:nth-child(1){
    animation-delay: 0s;
}

.image:nth-child(2){
    animation-delay: 5s;
}

.image:nth-child(3){
    animation-delay: 10s;
}


@keyframes fadeAnimation {
    0%, 20% {opacity: 1;}
    25%, 95% {opacity: 0;}
}

.subheader{
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    color: #383838;
    margin-bottom: 20px;
}

.package_item{
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #383838;
    width: 50%;
    padding: 20px;
    margin: 20px;
}

.spacing{
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 20px;
}

.package_header{
    margin-top: 20px;
    font-weight: 600;
    color: black;
    font-size: 32px;
}

.green_box{
    background-color: #EEFCEE;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.horizontal{
    border: 0.3px solid #0D600D;
    margin-bottom: 40px;
}

.package_deals{
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 40px;
}

.white_card{
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    width: 50%;
}

.package_deals_image{
    width: 40%;
    // height: 480px;
}

.package_deals_header{
    color: black;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    justify-content: center;
}

.package_deals_subtitle{
    font-weight: 400;
    font-size: 18px;
    color: black;
}

.package_deals_reason{
    font-weight: 600;
    font-size: 18px;
    color: black;
}

.project_container{
    position: relative;
    margin-bottom: 40px;
    border: 2px solid green;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.project_form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80%;
}

.inputRow{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    margin-top: 1rem;
    gap: 1rem;
    margin-bottom: 20px;
    width: 95%
}

.grid_item {
    max-width: 100%; /* Prevents children from growing beyond 30% of the parent */
    // justify-self: center; /* Optionally centers the item within its grid cell */
    // border: 2px solid red;
    width: 100%
}

.inputStyling{
    border-radius: 20px;
}

.required{
    display: flex;
    gap: 2px;
    font-weight: 500;
}

.required::after{
    content: "*";
    color: red;
}

.rel_form{
    position: relative;
}

.rel_form img{
    position: absolute;
    top: 40%;
    right: 10px;
    cursor: pointer;
}

// .timePickerContainer{
//     width: 100%;
//     // border: none;
//     // height: 20px;
// }

.project_submit_btn{
    display: flex;
    justify-content: center;
}

// .project_submit_btn button{
//     background-color: #0D600D;
//     border-radius: 15px;
//     font-weight: 600;
//     font-size: 24px;
//     color: white;
//     padding: 20px;
//     width: 30%;
//     font-weight: 600;
// }

.submit_btn{
    width: 35%;
    font-weight: 600;
    font-size: 20px;
}

.project_form_header{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: black;
    font-size: 40px;
}

.project_form_subheader{
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: black;
    font-size: 24px;
}

@media (max-width:768px) {
    .imageContainer{
        display: none;
    }

    .first_div{
        width: 100%;
    }

    .spacing {
        flex-direction: column;
    }

    .package_deals{
        flex-direction: column;
        gap: 20px;
    }

    .inputRow{
        display: flex;
        flex-direction: column;
    }

    .white_card{
        width: 100%;
    }

    .package_item{
        width: 100%;
        margin: 0px;
    }

    .spacing{
        gap: 20px;
    }

    .project_form{
        justify-content: center;
        position: static;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        width: 100%;
    }

    .package_deals_subtitle{
        font-size: 16px;
    }

    .package_deals_image{
        margin: auto;
        width: 80%;
    }

    .formBg{
        display: none;
    }

    .project_container{
        position: static;
    }

    .exploreBtn{
        width: 100%;
    }

    .required::after{
        content: "*";
    }

    .project_form_header{
        font-size: 24px;
    }

    .project_form_subheader{
        font-size: 16px;
    }

    .submit_btn{
        width: 100%;
    }
}

