.overallContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}
 
.containerI {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));  
    gap: 5em;
    width: 80%;/* Adjust width to fit the screen */
  max-width: 1200px; /* Set a max-width to prevent excessive stretching on large screens */
  margin-left: auto;
  margin-right: auto;
  // overflow-x: hidden;
}
 
.noListing {
    width: 20em;
    height: 12em;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 5em;
    border-top-width: 0.5em;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 255, 255, 0.02);
}

@media screen and (max-width: 768px){
    .containerI{
        display: flex;
        width: 85%;
        flex-direction: column;
        align-items: center;
    }
 
    .griditem{
        margin-bottom: 2em;
    }
}