
.section{
   width: 50%; 
    margin-left:12rem;
    margin-bottom: 5em;

    @media screen and (max-width: 768px) and (min-width: 320px) {
        width: 80%;
        margin-left:30px;
    }
        
    }
