.home-inner {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 5em auto;
  }
  
  .first-layer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 20em;
  }
  
  .first-layer-texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 55%;
  }
  
  .first-layer-texts-para {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: #383838;
  }
  
  .first-layer-texts-span {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #383838;
    margin-top: 0.5em;
  }
  
  .first-layer-texts > div {
    width: 12em;
    height: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #91ea91;
    border-radius: 0.2em;
    margin-top: 2em;
  }
  
  .first-layer-texts > div > span {
    font-weight: 400;
    font-size: 20px;
    line-height: 39px;
    color: #ffffff;
  }
  
  .first-layer-img {
    width: 40%;
    height: 100%;
    position: relative;
    border-radius: 0.4em;
  }
  
  .first-layer-image1 {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.4em;
  }
  .first-layer-image {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .first-layer-image-top {
    position: absolute;
    bottom: 86%;
    right: 90%;
    width: 7em;
    height: 7em;
  }
  
  .first-layer-image-bottom {
    position: absolute;
    top: 88%;
    left: 90%;
    width: 7em;
    height: 7em;
  }
  
  .coverage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #383838;
    height: 18em;
    margin-bottom: 2em;
    border-radius: 0.5em;
    // overflow: hidden;
    width: 100%;
  }
  
  .coverage-img-cont {
    width: 27.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .coverage-img {
    width: 80px;
    height: 80px;
  }
  
  .coverage-cont-text {
    width: 72.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .coverage-cont-text-inner {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 95%;
    margin: auto;
  }

  .coverage-cont-text-inner-each {
    width: 100%;
    // height: 30%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1em 0
  }

  .coverage-li-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 100%;
  }

  .coverage-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #383838;
    border: 1.5px solid black;
    margin-top: 8px;
  }
  
  .coverage-cont-text-inner-each-li {
    font-weight: 600;
    font-size: 15px;
    color: #383838;
    width: 90%;
    text-align: justify;
  }

  .coverage-cont-text-p {
    font-weight: 600;
    font-size: 21px;
    line-height: 64px;
    color: #383838;
    margin-left: 5%;
    width: 90%;
  }
  
  .coverage-cont-text-span {
    font-weight: 400;
    font-size: 19px;
    line-height: 36px;
    color: #383838;
    margin-left: 5%;
    width: 90%;
  }
  
  .coverage-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 10em;
  }
  
  .rent-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 56px;
    color: #383838;
    margin-bottom: 2em;
  }
  
  .black-but {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #383838;
    color: white;
    border-radius: 0.4em;
    min-width: 180px;
    height: 3em;
    width: 12em;
    min-height: 40px;
    margin-top: 2em;
  }
  
  .benefits {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    margin: 5em auto;
    background-color: #fffafb;
  }
  
  .vertical-cross {
    width: 1px;
    height: 70%;
    background-color: #cbc6c6;
    position: absolute;
    left: 50%;
    top: 15%;
    border: none;
  }
  
  .horizontal-cross {
    height: 1px;
    width: 60%;
    background-color: #cbc6c6;
    position: absolute;
    top: 50%;
    left: 20%;
    border: none;
  }
  
  .benefits-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 56px;
    color: #383838;
    margin: 1.5em auto;
  }
  
  .benefits-box-cont {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    position: relative;
    margin-bottom: 4em;
  }
  
  .benefits-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #616161;
    border-radius: 0.5em;
  }
  
  .benefits-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #383838;
    margin-bottom: 3em;
    text-align: center;
    padding: 0 0.5em;
  }
  
  .benefits-img {
    width: 70px;
    height: 70px;
    margin-top: 3em;
    margin-bottom: 1em;
  }
  
  @media screen and (max-width: 768px) {
    .home-inner {
      width: 95%;
    }
    .first-layer {
      flex-direction: column;
      height: auto;
    }
  
    .first-layer-texts {
      width: 100%;
      align-items: center;
    }
  
    .first-layer-img {
      width: 100%;
      margin-top: 2em;
    }
  
    .first-layer-image-top {
      left: 82%;
      bottom: 84%;
      width: 5em;
      height: 5em;
    }
  
    .first-layer-image-bottom {
      right: 84%;
      top: 84%;
      width: 5em;
      height: 5em;
    }
  
    .coverage{
      flex-direction: column;
      height: auto;
    }
  
    .coverage-img-cont{
      width: 100%;
    }
  
    .coverage-img{
      margin: 3em
    }
  
    .coverage-cont-text{
      width: 100%
    }
  
    .vertical-cross{
      display: none;
    }
  
    .horizontal-cross{
      display: none;
    }
  
    .benefits-box-cont {
      width: 90%;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  