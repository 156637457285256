.inputName{
    position: relative;
}

.niceSelect{
    border: 2px solid;
    border-color: var(--border-color-1);
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    height: 65px;
    line-height: 60px;
    min-width: 200px;
    width: 100%;
    margin-bottom: 30px;

    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    float: left;
    font-family: inherit;
    outline: 0;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left!important;
    transition: all .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.niceSelect::before{
    content: "\f063";
    font-size: 14px;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    color: var(--ltn__secondary-color);
}

.variant2{
    border-radius: 0;
    border: 2px solid #ededed;
    height: 50px;
    line-height: 46px;
    min-width: 190px;
}

.variant2::before{
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    width: 5px;
}

.niceSelect .option.selected {
    font-weight: 700;
}

.current{
    font-weight: 600;
}

.variant2Option{
    line-height: 1.8 !important;
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 5px !important;
}

.option{
    margin-top: 0;
    white-space: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.8;
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: 0;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.option:hover {
    background-color: #f6f6f6;
}

.list{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow-y: scroll;
    height: 200px;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(.75) translateY(-21px);
    -ms-transform: scale(.75) translateY(-21px);
    transform: scale(.75) translateY(-21px);
    -webkit-transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
    z-index: 9;
}

.open {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
}