.home-container {
  width: 100vh;
  min-height: 100vh;
}

.home-inner {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 5em auto;
}

.first-layer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 24em;
}

.first-layer-texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 55%;
}

.first-layer-texts-para {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #383838;
}

.first-layer-texts-span {
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #4d4d4d;
  margin-top: 0.5em;
}

.first-layer-texts > div {
  width: 12em;
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #91ea91;
  border-radius: 0.2em;
  margin-top: 2em;
}

.first-layer-texts > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  color: #ffffff;
}

.first-layer-img {
  width: 40%;
  height: 100%;
  position: relative;
  border-radius: 0.4em;
}

.first-layer-image1 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.4em;
}
.first-layer-image {
  display: block;
  width: 100%;
  height: 100%;
}

.first-layer-image-top {
  position: absolute;
  bottom: 86%;
  left: 86%;
  width: 7em;
  height: 7em;
}

.first-layer-image-bottom {
  position: absolute;
  top: 88%;
  right: 88%;
  width: 7em;
  height: 7em;
}

.our-offers {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10em;
}

.our-offers-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.our-offers-header {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  color: #383838;
}

.our-offers-text {
  font-weight: 700;
  font-size: 25px;
  line-height: 64px;
  color: #383838;
  padding-bottom: 1em;
}

.offer-option {
  width: 32%;
  height: 45em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #383838;
  position: relative;
  border-radius: 0.5em;
}

.offer-option-image-container {
  width: 100%;
  height: 40%;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  overflow: hidden;
}

.offer-option-texts {
  width: 90%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offer-option-texts > div {
  border: 0.5px solid #383838;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  position: absolute;
  bottom: 3%;
  padding: 0.1em 0.5em 0.1em 0.5em;
  width: 60%;
  border-radius: 0.3em;
}

.offer-option-texts > div > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #383838;
}

.offer-option-texts-p {
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  color: #383838;
  margin-bottom: 2em;
  text-align: left;
}

.third-layer {
  width: 70%;
  height: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 5em 0;
}

.third-layer-p {
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #8a8a8a;
  text-align: center;
  width: 65%;
}

.third-layer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #383838;
  color: white;
  border-radius: 0.4em;
  min-width: 180px;
  height: 3em;
  width: 12em;
  min-height: 40px;
}

.third-layer-img {
  width: 100px;
  height: 100px;
}

.third-layer-header {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  color: #383838;
  text-align: center;
}

.has-insurance {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
}

.has-insurance-a {
  width: 60%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid #91ea91;
  border-radius: 0.5em;
  margin-top: 1em;
  background-color: #f4f9f0;
}

.has-insurance-a-1 {
  width: 47%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.has-insurance-a-2 {
  width: 47%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #383838;
  border-radius: 0.5em;
}

.has-insurance-a-1 > span {
  font-weight: 600;
  font-size: 22px;
  line-height: 56px;
  color: #383838;
  text-align: center;
}

.has-insurance-a-2 > span {
  font-weight: 600;
  font-size: 22px;
  line-height: 56px;
  color: #ffffff;
  text-align: center;
}

.has-insurance-b {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #383838;
  border-radius: 0.5em;
  margin-top: 3em;
  min-height: 200px;
}

.has-insurance-b-each {
  width: 95%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.3px solid #8a8a8a;
}

.has-insurance-b-each-l {
  min-width: 30%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.has-insurance-b-each-r {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #91ea91;
  text-decoration: underline;
}

.has-insurance-b-each-l > img {
  height: 32px;
  width: 32px;
  margin-right: 1em;
}

.has-insurance-b-each-l-texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.has-insurance-b-each-l-texts > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #383838;
  text-align: left;
}

.pagination-div {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
}

.pagination-div-but {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #91ea91;
  border-radius: 0.3em;
}

.pagination-div-but > span {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  padding: 0.3em 1.2em;
}

.no-active-policies {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #383838;
  text-align: center;
  margin: auto
}

@media screen and (max-width: 768px) {
  .home-inner {
    width: 95%;
  }
  .first-layer {
    flex-direction: column;
    height: auto;
  }

  .first-layer-texts {
    width: 100%;
    align-items: center;
  }

  .first-layer-img {
    width: 100%;
    margin-top: 2em;
  }

  .first-layer-image-top {
    left: 82%;
    bottom: 84%;
    width: 5em;
    height: 5em;
  }

  .first-layer-image-bottom {
    right: 84%;
    top: 84%;
    width: 5em;
    height: 5em;
  }

  .our-offers-options {
    flex-direction: column;
  }

  .offer-option {
    width: 95%;
    margin-bottom: 1em;
    height: auto;
  }

  .offer-option-texts-p {
    margin-bottom: 5em;
  }

  .third-layer {
    width: 100%;
  }

  .third-layer-p {
    width: 90%;
  }

  .first-layer-texts-para {
    font-size: 35px;
  }

  .has-insurance-a {
    width: 90%;
    flex-direction: column;
    height: auto;
  }

  .has-insurance-a-1,
  .has-insurance-a-2 {
    width: 100%;
    margin-bottom: 1em;
  }

  .has-insurance-a-2 {
    height: auto;
  }

  .has-insurance-b {
    width: 100%;
  }

  .has-insurance-b-each {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 1em 0;
  }

  .has-insurance-b-each-l {
    width: 100%;
    margin-bottom: 1em;
  }

  .pagination-div {
    flex-direction: column;
    align-items: center;
  }

  .pagination-div-but {
    width: 100%;
    margin-bottom: 1em;
  }
}
