.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Use rgba() with separate values for red, green, blue, and alpha */
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  @media screen and (max-width: 768px){
    .backdrop {
    //   align-items: flex-start;
      /* top: 5%; */
    }
  }