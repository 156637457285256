.demo_logo_last {
    width:  5em;
    height: 5em;
    border-radius: 100%;
    margin-bottom: 15px;
  }
  .profile{
    display: flex;
    flex-direction: column;
  }
  .first_section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .transact_card{
    background-color: #FFFEFA;
    width: 65%;
    display: flex;
    justify-content: center;
    border: 1.5px black solid;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    border-radius: 10px;
    // gap: 20px;
    padding-bottom: 20px;
  }

  .view_link{
    color: #75B343;
    font-weight: 700;
    font-size: 12px;
  }

  .rented_card{
    background-color: #FDF8F6;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1.5px black solid;
    padding-top: 20px;
    border-radius: 10px;
    // gap: 10px;
  }

  .boldText{
    font-weight: 600;
    font-size: 28px;
    color: #383838;
  }

  .caption{
    font-weight: 500;
    font-size: 14px;
  }

  .second_section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .viewPropCard{
    background-color: #F6FDF6;
    width: 30%;
    border: 1.5px solid #383838;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .gridCard{
    width: 65%;
  }

  .first_grid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .mortgage_card{
    width: 50%;
    background-color: #FFF7F5;
    border-radius: 10px;
    border: 1.5px #383838 solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-right: 20px;
  }

  .purchase_card{
    background-color: #FFFEFA;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    border-radius: 10px;
    border: 1.5px #383838 solid;
  }

  .saved_prop_card{
    width: 50%;
    background-color: #FFFEFA;
    border-radius: 10px;
    border: 1.5px #383838 solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-right: 20px;
  }

  .inspect_card{
    background-color: #F6FDF6;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    border-radius: 10px;
    border: 1.5px #383838 solid;
  }

  .last_grid{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .disappear {
    display: none;
  }

  .transac_header{
    background-color: #FFFEFA;
    width: 100%;
    display: flex;
    justify-content: start;
    border: 1.5px black solid;
    flex-direction: column;
    padding-top: 20px;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    margin-bottom: 40px;
  }

  .category_view{
    border: 1px #75B343 solid;
    border-radius: 10px;
    padding: 10px;
    width: max-content;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }

  .transac_table{
    border: 3px #383838 solid;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .transac_item{
    display: flex;
    align-items: center;
    // gap: 10px;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .itemImage{
    padding-right: 8px;
  }

  .name_address_time{
    // width: 200px;
    width: 30%;
    display: flex;
    flex-direction: column;
    // gap: 5px;
    position: relative;
    // border: 1px solid red;
    align-items: flex-start;
  }

  .name{
    // text-overflow: ellipsis;
    font-weight: 500;
    font-size: 20px;
    color: #4D4D4D;
    position: absolute;
    left: 0;
    top: -20px;
    // bottom: 40;
    white-space: nowrap;
    // overflow: hidden;
    // max-width: 100%;
    // display: inline-block;
  }

  .address{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: #727272;
    margin-top: 10px;
  }

  .time{
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
    color: #8A8A8A;
  }

  .amountText{
    color: #383838;
    font-weight: 500;
    font-size: 14px;
  }

  .timeText{
    font-weight: 500;
    font-size: 14px;
  }

  .dateText{
    font-size: 14px;
    font-weight: 400;
    color: #383838;
  }



  .bottom_style{
    border-bottom: 1px #383838 solid;
  }

  .status{
    padding: 5px 10px;
    border-radius: 5px;
    color: #383838;
    font-weight: 500;
    display: inline-block;
    width: 144px;
    display: flex;
    justify-content: center;
    font-size: 14px;

    &.completed{
      background-color: #A9EFA9;
    }

    &.pending{
      background-color: #E79483;
    }

    &.part-payment{
      background-color: #F9D553;
    }

    &.default{
      background-color: aliceblue;
    }
  }

  .view_nav{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .mobile_transac_view{
    display: none;
  }


  @media (max-width: 768px){
    .homebuddy_info_layout{
      width: 100%;
    }

    .first_section{
      flex-direction: column;
    }

    .transact_card{
      width: 100%;
      margin-bottom: 20px;
    }

    .rented_card{
      width: 100%;
    }

    .second_section{
      flex-direction: column;
    }

    .viewPropCard{
      width: 100%;
      margin-bottom: 20px;
    }

    .first_grid{
      flex-direction: column;
      gap: 20px;
    }

    .gridCard{
      width: 100%;
    }

    .mortgage_card{
      width: 100%;
      margin-right: 0px;
    }

    .purchase_card{
      width: 100%;
    }

    .last_grid{
      flex-direction: column;
      gap: 20px;
    }

    .saved_prop_card{
      width: 100%;
    }

    .inspect_card{
      width: 100%;
    }

    .transac_table{
      display: none;
    }

    .view_nav{
      display: none;
    }

    .mobile_transac_view{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      margin-bottom: 30px;
    }

    .view_transac_btn{
      background-color: #75B343;
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .transac_drop_down{
      display: none;
    }
  }