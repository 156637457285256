.containerAdmin {
  width: 85%;
  height: 60vh;
  margin: 0 auto 5em auto;
  background-color: rgba(145, 234, 145, 0.05);
  border-radius: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: rgba(128, 128, 128, 0.05);
}

.container-card {
  width: 40%;
  height: 80%;
  background-color: rgba(128, 128, 128, 0.05);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  margin: auto 5% auto 5%;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-avatar {
  --size: 60px;
  background: linear-gradient(to top, #ebf4eb 0%, #bfe8bf 100%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transition: transform 0.2s ease;
  margin: 1.5rem 0 2rem 0;
  justify-self: flex-start;
  overflow: hidden;
}

.card-bar {
  height: 2.5px;
  width: 90%;
  background: linear-gradient(to top, #ebf4eb 0%, #bfe8bf 100%);
  border-radius: 1em;
  margin-top: 15%;
}

.card-avatarI {
  --size: 60px;
  background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transition: transform 0.2s ease;
  justify-self: flex-start;
  overflow: hidden;
  margin: 1.5rem 0 2rem 0;
}

.card-barI {
  height: 2.5px;
  width: 90%;
  background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%);
  border-radius: 1em;
  margin-top: 15%;
}

.card-titles {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 2rem;
  width: 85%;
  max-width: 90%;
  text-align: center;
}

.card-subtitle {
  color: #859ba8;
  font-size: 0.8em;
  max-width: 90%;
  text-align: center;
}
