.container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px 0;
}

.table {
  //background-color: blueviolet;
  width: 55%;
  border: 1px solid var(--border-color-1);
}

.table td {
  padding: 10px 15px;
}

.table th {
  padding: 10px 15px;
}

.table tr > td:last-child {
  text-align: right;
}

.headerRow {
  border-bottom: 0.3px solid var(--border-color-1);
}

.balanceRow {
  border-top: 0.3px solid var(--border-color-1);
}

.debit {
  color: var(--wrong);
}

.credit {
  color: var(--correct);
}




.saleContainer {
  width: 55%;
  border-radius: 2%;
  border: 1px solid #ECECEC;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.saleContainer > p {
  width: 85%;
  padding-left: 1em;
  font-weight: 500;
  padding-top: 2em;
}

.saleTransfer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  padding-left: 1em;
  padding-bottom: 1em;
}

.leftItem {
  font-weight: bold;
  color: #3a3a3a;
  padding-right: 1em;
}


.confirm-button {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 auto 1em auto
}

.confirm-but {
  height: 3em;
  width: 10em;
  background-color: #91ea91;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
}

.confirm-but:hover{
  background-color: white;
  color: #91ea91;
  border: 1px solid #91ea91
}

@media screen and (max-width: 768px) {
    .saleContainer{
        width: 90%
    }
    .leftItem {
        padding-right: 0.2em;
    }
}
