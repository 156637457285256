.cardArea{
    background-color: var(--section-bg-1);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.cardArea:hover{
    background-color: #D5E1E4;
}

.cardArea:active{
    transform: scale(0.97);
}

.cardLogo{
    width: 100%;
    display: flex;
    align-items: center;
}

.cardLogo h6{
    margin: 0;
    font-size: 16px;
}

.nameNumber{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    width: 100%;
    flex: 0.8;
}

.nameNumber p{
    padding: 0;
    margin: 0;
    font-size: 14px;
}