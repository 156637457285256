.container{
    //border: 1px solid var(--border-color-1);
    flex: 1;
    padding: 10px 0;
}

.breakdown{
    border: 1px solid var(--border-color-1);
    padding: 20px;
    position: relative;
}

.card{
    border: 1px solid var(--border-color-1);
    padding: 20px;
    position: relative;
}

.cardTitle{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.cardTitle h6{
    margin: 0;
    margin-left: 5px;
}

.balanceRow{
    border-top: 1px solid var(--border-color-1);
    border-bottom: 1px solid var(--border-color-1);
}

.table{
    width: 100%;
}

.table td{
    padding: 12px 0px;
    font-size: 14px;
}

.bluryText{
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.bluryText td{
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.table tr > td:last-child{
    text-align: right;
}

.tableOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 2;
    cursor: pointer;
    opacity: 1;
}

.dropdown{
    min-width: none;
    width: auto;
    margin: auto;
    font-size: 14px;
}

.paymentRow td{
    padding-top: 30px;
}

.input{
    height: 50px !important;
}

.label{
    font-size: 14px;
}

.btn{
    padding: 10px 25px !important;
    font-size: 14px !important;
}




// installmental payment card


.saleContainer {
    width: 55%;
    border-radius: 2%;
    border: 1px solid #ECECEC;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }
  
  .saleContainer > p {
    width: 85%;
    padding-left: 1em;
    font-weight: 500;
    padding-top: 2em;
  }
  
  .saleTransfer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 85%;
    padding-left: 1em;
    padding-bottom: 1em;
  }
  
  .leftItem {
    font-weight: bold;
    color: #3a3a3a;
    padding-right: 1em;
  }

  .confirm-button {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 auto 0 auto
  }

  .confirm-but {
    height: 3em;
    width: 10em;
    background-color: #91ea91;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
  }

  .confirm-but:hover{
    background-color: white;
    color: #91ea91;
    border: 1px solid #91ea91
  }
  
  
  @media screen and (max-width: 768px) {
      .saleContainer{
          width: 90%
      }
      .leftItem {
          padding-right: 0.2em;
      }
  }
  