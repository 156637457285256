.cardArea{
    background-color: var(--section-bg-1);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.cardArea:hover{
    background-color: #D5E1E4;
}

.cardArea:active{
    transform: scale(0.97);
}

.cardLogo{
    display: flex;
    align-items: center;
    flex: 1;
}

.cardLogo img{
    width: 50px;
    max-height: 50px;
}

.nameNumber{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
}

.nameNumber p{
    padding: 0;
    margin: 0;
}