.container{
    //overflow: hidden;
    background-color: green;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.slidershow{
    width: 100%;
    height: auto;
    overflow: hidden;
}