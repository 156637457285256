// @import 'bootstrap/dist/css/bootstrap.css';

$royalBlueDark: rgba(2, 38, 107, 1);
$safetyOrange: rgba(246, 108, 4, 1);
$redPigment: rgba(228, 46, 38, 1);
$oliveDrab: rgba(53, 53, 25, 1);
$black: rgba(0, 0, 0, 1);
$flax: rgba(255, 255, 255, 1);
$androidGreen: rgba(174, 204, 53, 1);

$theme-colors: (
  'warning': $redPigment,
  'secondary': $safetyOrange,
  'primary': $royalBlueDark,
  'light': $oliveDrab,
  'dark': $black,
  'success': $androidGreen,
);

$white: $flax;

$grid-breakpoints: (
  xs: 0,
  sm: 700px,
  md: 900px,
  lg: 1200px,
  xl: 1500px,
  xxl: 2300px,
);

$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 1100px,
  xl: 1300px,
  xxl: 2000px,
);

@import 'node_modules/bootstrap/scss/bootstrap';
