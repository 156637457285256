.imageOverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

.imageOverlay:hover{
    opacity: 1;
}

.image{
    width: 100%;
    height: 100%;
}

.image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.btn{
    display: inline-block;
    padding: 5px 12px;
    margin-bottom: 0;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid black;
    text-decoration: none;

    color: #333;
    background-color: rgb(241, 236, 236);
    border-color: #ccc;
}

