.title h3{
    font-weight: 500;
    // position: relative;
    z-index: -1;
}

.tablehead {
    // position: relative;
    z-index: -1;
}

.commentStatus {
  color: green;
font-size:xx-large
}

.buttoncontainer:hover {
  background-color: var(--ltn__paragraph-color);
}

.commentStatusFalse {
  color: red;
  font-size:xx-large
}

body.modal-open {
  overflow: hidden;
}

.clickableelement {
  cursor: pointer;
}

.lineClamp{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.myDiv {
    background-color: var(--section-bg-2);
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease; /* Add a smooth transition */
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* Define the hover effect */
    &:hover {
      background-color: green;
    }
  }

.myDivWrong {
    background-color: var(--section-bg-2);
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease; /* Add a smooth transition */
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* Define the hover effect */
    &:hover {
      background-color: red;
    }
  }


  .overlay {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .switchItem {
    width: '50%';
    // height: '10%';
    align-items: 'center';
    justify-content: 'center';
    // border-top-left-radius: 5em;
    // border-bottom-left-radius: 5em;
  }

  .switchItemI {
    width: '50%';
    // height: '10%';
    align-items: 'center';
    justify-content: 'center';
    // border-top-right-radius: 5;
    // border-bottom-right-radius: 5;
  }

  .switchText {
    font-weight: '500';
    font-size: 18;
    line-height: 21.8;
  }

  .error {
    width: 60%;
    height: 5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ecf4f4;
    align-self: center;
    border-radius: 1%;
}

.backButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(145, 234, 145, 0.1);
  border-radius: 1em;
}

.backButton span {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  color: #3A3A3A;
}


.card {
  width: 90%;
  display: flex;
  flex-direction: row;
  border-radius: 1em;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
  margin-top: 2em;
  justify-content: space-between;
  align-items: stretch;
}

.cardDataCont {
  flex: 1;
  margin-bottom: 5em;
}

.cardData {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propTitleContainer {
  margin-top: 1em;
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.propTitle {
  font-size: large;
  color: #3A3A3A;
  font-weight: bold;
}

.address {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1em;
}

.address span {
  margin-right: 0.5em;
  font-size: 14px;
}

.meta {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.2em 0 1.2em 3em;
}

.metadata {
  background-color: rgba(145, 234, 145, 0.3);
  padding: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.8em;
  min-width: 4em;
} 

.metadata span {
  font-size: 12px;
  margin: 0 0.3em 0 0.3em
}

.meta2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 3em;
  width: 100%;
}

.meta2data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.meta2data span {
  font-size: 12px;
  margin-left: 1.5em;
}

.imgContainer {
  width: 48%;
  height: 100%;
  min-height: 12em;
  overflow: hidden;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.imgContainer img {
  display: block;
  width: 100%;
  height: max-content;
  min-height: 12em;
}

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3em;
}

.msghead {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.msghead span {
  font-weight: bold;
  font-size: 13px;
  color: #3A3A3A;
}

.msgTxt {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(145, 234, 145, 0.1);
  margin-top: 1em;
  min-height: 4em;
}

.acceptedTxt {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(145, 234, 145, 0.1);
  margin-top: 1em;
  min-height: 4em;
}

.accDiv {
  flex: 1;
  margin-top: 1em;
}

.msgTxt span {
  font-size: 13px;
  margin-left: 1em;
  color: #3A3A3A;
}

.acceptedTxt span {
  font-size: 13px;
  margin-left: 1em;
  color: #3A3A3A;
}

.bar {
  height: 1.5em;
  width: 1em;
  background-color: rgba(145, 234, 145, 0.8);
  margin-right: 0.5em;
}

.input {
  width: 100%;
  height: 9em;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.buttonInner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 10em;
  height: 2.5em;
  border-radius: 0.5em;
  background-color: #91EA91;
  align-items: center;
  justify-content: center;
  color: #3A3A3A;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.button1 {
  width: 10em;
  height: 2.5em;
  border-radius: 0.5em;
  background-color: white;
  align-items: center;
  justify-content: center;
  color: #91EA91;
  border: 1px solid #91EA91;
  display: flex;
  flex-direction: row;
}

.button span {
  font-size: 12px !important;
  font-weight: bold;
  color: white;
}

.button1 span {
  font-size: 12px !important;
  font-weight: bold;
}

.confirmRejectDiv {
  width: 90%;
  height: 85%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.confirmRejectDiv p{
  text-align: center;

}

.confirmRejectDivButtons {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 45%;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  background-color: #91EA91;
}

.btn1 {
  width: 45%;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  background-color: '#FFFFFF';
  border: 1px solid #91EA91
}

@media screen and (max-width: 768px) {
  .card {
    flex-direction: column;
    transform: rotate(180deg);
  }

  .cardData{
    width: 100%;
    transform: rotate(180deg);
    border-bottom-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  .propTitleContainer{
    margin-left: 0.5em;
    align-self: center;
  }

  .metadata {
    min-width: 0;
  }

  .meta {
    width: 95%;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  .meta2 {
    width: 95%;
    align-self: center;
    margin: 0 auto 2em auto
  }

  .imgContainer {
    width: 100%;
    border-bottom-right-radius: 0em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    transform: rotate(180deg);
    min-height: 12em;
  }
}