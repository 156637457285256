.contentWrapper{
    height: 0;
    overflow: hidden;
    transition: height ease 0.35s, padding ease 0.35s;
    padding: 0 30px;
    border: 0px solid var(--border-color-1); 
}

.content{
    margin: 0 !important;
    padding: 0 !important;
}

.checkoutInfo{
    padding: 30px;
    border: 1px solid var(--border-color-1); 
}

a{
    cursor: pointer;
}