.cardArea{
    background-color: var(--section-bg-1);
    padding: 20px 30px;
    display: flex;
    align-items: center;
    width: auto;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    
}

.cardArea p{
    margin: 0;
}

.cardArea:hover{
    transform: scale(1.05);
}

.container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0;
}