.logoImage{
    //margin-right: 10px;
    height: 60px;
    width: 195px;
    // background-color: rgba(255, 255, 255, 0.1);
    // backdrop-filter: blur(10px);
    // background-color: #e6f7ff;
    // background-color: #afa0e4;
    // border: 1px solid transparent;
    overflow: hidden;
    border-width: 0;
   
    // border-radius: 70%;
    // background-color: #2f4f4f;
    // background-color: var(--ltn__secondary-color);
    // border-radius: 3%;
    // box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
    // border: 1px solid #146E3F
    // border-color: '';
}

.logoImage2{
    height: 60px;
    width: 100px;
    border: 1px solid transparent;
    overflow: hidden;
    border-width: 0;
}

.logoImage img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.upcoming{
   background-color: darkgreen;
    color:white ; 
    width: 164px;
    height: 49px;
    border-radius: 16px;
}

.logoLabel{
    font-family: 'Poppins';
}

.logoLabel h3{
    padding: 0;
    color: #10387e;
    margin: 0;
    position: relative;
    font-style: italic;
}

.ahMenuItem{
    color: white !important;
    justify-content: center;
}

.ahMenuItem:hover{
    color: var(--ltn__secondary-color-3) !important;
}

.mainMenu{
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
}

.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2%;
    justify-content: space-between;
    width: 100%;
}

.special-link{
   font-weight: bold;
}

.picture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 0.5em;
}

.noAuthPicture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 0.5em;
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.picturedropdowncontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Times New Roman', Times, serif;
    font-size: 12px;
    color: white;  
    margin-right: 0.5em;
    position: relative
}

.listUnderPicture {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    top: 5em;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
}

.listUnderPicture span {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    padding-right: 2.5em;
    font-family: 'Times New Roman', Times, serif;
    color: #3A3A3A;
    font-size: 12px;
}

.nav {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.toggleAndPerson {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.addproperty {
    color: #91EA91;
    width: auto;
    height: auto;
    margin-left: 0.5em;
}

.li-nav-menu{
    padding-right: 3em;
}


@media screen and (max-width: 768px) {
    .logoImage{
        height: 60px;
        width: 170px;
        align-items: center;
        margin-bottom: 0.5em;
    }

    .picturedropdowncontainer{
        min-width: 8.5em;
    }

    .addproperty {
        display: none;
    }
}

/* Responsive styles */
@media (min-width: 1200px){
    .mobileMenu{
        display: none;
    }
    
}

@media (max-width: 1200px){
    .mainMenu{
        display: none;
    }
    .secondaryMenu{
        flex: auto !important;
        margin-left: 2%;
    }
}

