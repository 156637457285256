.supercontainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-bottom: 5em;
}

.gridContainer {
  width: 90%;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns with equal width */
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  height: 120vh; /* Full height of the viewport */
  gap: 2rem;
}

.fonts {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
}

.fontsTopic {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.noListing {
  width: 20em;
  height: 12em;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-bottom: 5em;
  border-top-width: 0.5em;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 255, 255, 0.02);
}

.heading {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  color: #7a7a7a;
  margin: 2em 0 2em 0;
  align-self: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile-container {
  width: 85%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: auto;
  align-self: center;
  margin: 0 auto 5em auto;
}

.profile-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(145, 234, 145, 0.05);
  height: 50px;
}

.profile-backII {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgba(145, 234, 145, 0.05);
  height: 50px;
  position: relative;
}

.dropdownfilter {
  position: absolute;
  width: 6em;
  height: 8em;
  border: 0.05px solid #91ea91;
  border-radius: 0.2em;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 3.5em;
  z-index: 999;
}

.filter-button {
  width: 5em;
  height: 3em;
  background-color: #91ea91;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 0.5em;
  margin-right: 2.5%;
}

.filter-button:hover {
  border: 1px solid #91ea91;
  color: #91ea91;
  background-color: white;
}

.dropdownoptions {
  width: 100%;
  height: 33.33%;
  border-bottom: 0.05px solid slategray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownoptions:hover {
  background-color: #9ce29c;
  color: white;
}

.profile-back-cont {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profile-main {
  background-color: rgba(128, 128, 128, 0.05);
  border-radius: 2em;
  width: 100%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2em;
}

.profile-info-main {
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // background-color: rgba(128, 128, 128, 0.05);
  margin: auto;
  border-radius: 2em;
}

.profile-info-1 {
  width: 45%;
  height: 100%;
  border-radius: 2em;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.profile-info-1-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em auto 0.5em auto;
}

.profile-info-1-info > p {
  text-align: justify;
  font-size: large;
  font-weight: 700;
  align-self: flex-start;
}

.profile-info-1-info-each {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid slategrey;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.profile-info-1-info-each > div {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-start;
}

.profile-info-1-img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  overflow: hidden;
}

.profile-info-1-img > img {
  display: block;
  width: 100%;
  height: 100%;
}

.profile-info-2 {
  width: 45%;
  height: 100%;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.profile-info-2A {
  width: 100%;
  height: 40%;
  background-color: white;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.profile-info-2A > p {
  text-align: justify;
  font-size: large;
  font-weight: 700;
  align-self: flex-start;
  width: 90%;
  margin: 0.5em auto 0.5em auto;
}

.profile-info-2B .line {
  width: 100%;
  height: 2px;
  background-color: rgb(243, 245, 247);
}

.profile-info-2B > p {
  text-align: justify;
  font-size: large;
  font-weight: 700;
  align-self: flex-start;
  width: 90%;
  margin: 0.5em auto 0.5em auto;
}

.profile-info-2B .each {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid slategrey;
  justify-content: space-between;
  margin: 0 auto 0.3em auto;
}

.profile-info-2B .each > span {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-start;
  max-width: 60%;
}

.profile-info-2A .line {
  width: 100%;
  height: 2px;
  background-color: rgb(243, 245, 247);
}

.profile-info-2A .contact {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  height: 80%;
  max-height: 80%;
}

.profile-info-2A .contact > ul li {
  margin: 0;
  padding: 0;
}

.profile-info-2B {
  width: 100%;
  height: 56%;
  background-color: white;
  border-radius: 2em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.profile-info-2B .norsa {
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: pink;
}

.mark-treated {
  width: 80%;
  margin: 0.5em auto 0.5em auto;
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #a8f3a8;
  color: #7a7a7a;
  border-radius: 0.5em;
}

.mark-treated:hover {
  border: 1px solid #91ea91;
  background-color: white;
  color: #91ea91;
}

.hasrsa {
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: #91ea91;
}

.notrsa{
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: pink;
}

@media screen and (max-width: 768px) {
  .gridContainer {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: auto;
  }

  .profile-main {
    height: auto;
  }

  .profile-info-main {
    flex-direction: column;
    height: auto;
  }

  .profile-info-1 {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
  }

  .profile-info-2 {
    width: 100%;
    height: auto;
  }

  .profile-info-2A {
    height: auto;
    margin-bottom: 1em;
  }

  .profile-info-2B {
    height: auto;
  }

  .profile-info-2B .each {
    margin-bottom: 1em;
  }
}
