.container {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
}

.superContainer {
  width: 100%;
  height: auto;
}

.eachProperty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 14em;
  width: 100%;
  border-radius: 1%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top-width: 0.5em;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 255, 255, 0.01);
  margin-bottom: 2%;
}

.imgContainer {
  width: 45%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: 0.1%;
  border-radius: 1%;
}

.image {
  width: 100%;
  height: 100%;
}

.metaData {
  width: 50%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
}

.eachData {
  width: 100%;
  max-height: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0.5%;
  overflow: hidden;
}

.data {
  width: 95%;
  height: 100%;
  align-items: flex-start;
  overflow: hidden;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fonts {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
}

.fontsTopic {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.statusContainer {
  width: 7em;
  height: 1.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.statusContainerI {
  width: 8em;
  height: 1.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.status{
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  justify-content: center;
}

.status i{
  color: white;
}

.amber{
  background-color: #FFBF00 !important;
}

.green{
  background-color: #4BB543 !important;
}

.noListing {
  width: 20em;
  height: 12em;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-bottom: 5em;
  border-top-width: 0.5em;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 255, 255, 0.02);
}

.paginationDiv {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4em;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }

  .eachProperty {
    margin-bottom: 1%;
    flex-direction: column;
    height: 24em;
    margin-bottom: 3em;
  }

  .imgContainer {
    width: 100%;
    height: 50%;
  }

  .metaData {
    width: 90%;
    height: 45%;
  }
}
