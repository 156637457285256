.apply-insurance-container {
  max-width: 100vw;
  // min-height: 100vh;
}

.apply-insurance-form {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 5em auto;
}

.header {
  align-self: flex-start;
  font-weight: 700;
  font-size: 20px;
  line-height: 55px;
  color: #383838;
}

.iterator-cont {
  align-self: flex-start;
  width: 20%;
  margin: 2em 0;
}

@media screen and (max-width: 768px) {
  .iterator-cont {
    width: 65%;
  } 
}