.section {
  width: 60%;
  margin-left: 16rem;

  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: 70%;
    margin-left: 4em;
  }
}

.input {
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--border-color-9);
  height: 65px;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  width: 100%;
  border-radius: 0;
  padding-right: 40px;
}

.ref {
  position: relative !important;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eyeIcon {
  position: absolute;
  bottom: 3em;
  right: 3em
}

.buttondiv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  @media screen and (max-width: 768px) and (min-width: 481px) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 320px) and (max-width: 470px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.confirmdiv {
  display: flex;
  justify-content: flex-start;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
