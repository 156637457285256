.container{
    height: 100%;
    width: 100%;
}
.itemDiv{
    margin-right: 20px;
}
.unavailable{
    display: flex;
    align-items: center;
    justify-content: center;
}
