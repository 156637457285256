.cardBody{
    padding: 15px 30px;
    box-shadow: var(--ltn__box-shadow-3);
    margin-top: 15px;
    position: relative;
    max-height: 280px;
    overflow-y: auto;
    margin-bottom: 15px;
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: auto;  /* Internet Explorer 10+ */
}

.cardBody::-webkit-scrollbar { /* WebKit */
    width: 0.8rem;
    height: 100%;
}

.cardBody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
}

.cardBody::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.cardBody::before{
    position: absolute;
    top: -3px;
    left: 25px;
    width: 10px;
    height: 10px;
    content: '';
    transform: rotate(45deg);
    background-color: #fff;
}

.selectedPayment{
    border: 2px solid var(--border-color-9);
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.cardLogo{
    display: flex;
    align-items: center;
    flex: 1;
}

.cardLogo img{
    width: 50px;
    max-height: 50px;
}

.nameNumber{
   margin-left: 10%;
}

.nameNumber p{
    margin: 0;
}

.colored {
    // filter: invert(20%)
    fill: #008800
}