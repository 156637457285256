@import "./css/style.css";

@font-face {
  font-family: "icomoon";
  src: local("icomoon"), url(./fonts/icomoon.ttf) format("tff"),
    url(./fonts/icomoon.woff) format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap");

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* --ltn_secondary-color: #ff5a3c; */
  /* Comment these 5 colors to go back to the default theme colors */
  --ltn_secondary-color: #91ea91;
  --ltn__secondary-color: #91ea91;
  --ltn__secondary-color-3: #91ea91;
  --ltn__color-6: #e5ffe5;
  --orange: #7b87da;
  --correct: #00ff44;
  --wrong: #ff2600;

  --ltn_paragraph-color: #5c727d;
  --ltn__heading-color: #071c1f;
  --ltn__box-shadow-3: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  --ltn__box-shadow-4: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
}

/* Modifiying for green-ish theme, comment out to go back to default */
.bg-overlay-theme-05:before {
  background: rgba(29, 72, 41, 0.05);
}

.mui-homes-rating label {
  display: block;
}

/* (min-width: 1024px) and (max-width: 1199px) {
  h1 {
    font-size: 30px;
  }
} */

@media (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  p {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  h6 {
    font-size: 16px;
  }
}

a {
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

input[type="number"]:focus {
  border-color: var(--ltn_secondary-color);
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1200px;
  }
}
