.tabContent{
    width: 100%;
}

.container {
    width: 30em;
    height: 16em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    // background: linear-gradient(135deg, rgb(0, 255, 214) 0%, rgb(8, 226, 96) 100%);
    // background-color: rgb(223, 218, 218);
    // border: 1px solid #009900;
    background-color: #ecf4f4;
    // border-radius: 0.2em;
    // background: radial-gradient(#b0e633, #53ef7d);
    // border-width: 0.5em;
    // border-color: 
}

.headerCancel {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: large;
    font-family:monospace;
    margin-bottom: 1em;
    margin-top: 0.8em;
    margin-left: 1.5em;
    color: black;
    font-weight: bold;
    align-self: center;
}

.body {
    width: 85%;
    margin-bottom: 1em;
    margin-left: 1.5em;
    color: black;
    font-size: large;
    font-family:monospace;
    font-weight: 300;
    align-self: center;
    text-align: justify;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin-left: 1.5em;
    color: black;
    margin-top: 0.5em;
}

.icon {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


@media screen and (max-width: 768px) {
   .container {
    width: 20em;
    height: 17em;
   }
  }

@media screen and (max-width: 378px) {
   .container {
    width: 15em;
    height: 20em;
   }
  }
