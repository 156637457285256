.amount{
    display: flex;
    justify-content: space-between;
}

.amount p{
    color: black;
}

.amount h4{
    color: black;
}

.customerInfo{
    background-color: #edeff1;
    padding: 15px;
    border-radius: 10px;
}

.customerInfo > p{
    margin-bottom: 1em;
}

.customerDetail{
    display: flex;
    justify-content: space-between;
}

.customerDetail p{
    font-weight: 600;
    margin: 0;
    font-size: 15px;
}

.billInfo{
    margin-top: 1.5em;
}