.title h3{
    font-weight: 500;
}

.lineClamp{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.debit{
    color: #eb2a2a;
}

.credit{
    color: #0fe432;
}

.modalContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputContainer{
    width: 100%;
    height: 3.5em;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
}

.eachInput{
    width: 100%;
    height: 3em;
    border: 1px solid rgb(160, 163, 160);
    border-radius: 2.5%;
    padding: 0.5em;
}

.inputAndHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 5em;
    width: 40%;
}

.headingTransaction {
    align-self: center;
    margin-bottom: 2em;
}

.inputHeading {
    font-weight: 400;
}

.fund {
    width: 98%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ecf4f4;
    align-self: center;
}

.buttonContainer{
    width: 100%;
    height: 3.5em;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
}

