.navHeader{
    background-image: url('../../img/bg/14.jpg');
}

.pageTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
}

.noBottomMargin{
    margin-bottom: 0 !important;
}