.textInput input{
    margin-bottom: 0 !important;
}

.columnSU {
    display: flex;
    flex-direction: column;
}

.logoImage{
    height: 60px;
    width: 195px;
    overflow: hidden;
    border-width: 0;
}

.logoContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-left: 7%;
    margin-top: 0.5em;
}

.logoContainerI {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5em;
    width: 90%;
    margin-bottom: 6em;
}

.logoContainerWel {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5em;
    width: 90%;
    margin-bottom: 2.5em;
}

.welcome {
    font-size: large;
    font-weight: bold;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-left: 7.5%;
    margin-top: 3em;
}

.consents {
    padding-left: 1em;
    text-align: justify;
}

.formContainer {
    display: flex;
    flex-direction: row;
}

.formContainerI {
    display: flex;
    flex-direction: row;
    max-height: 170vh;
}

.inner {
    margin-top: 5%;
    margin-bottom: 2em;
    width: 50%;
}

.sideImg {
    width: 100%;
    height: 100%;
}

.sideImgContainer {
    width: 50%;
}

.greentxt {
    color: #91EA91;
}

.title {
    font-weight: bold;
    font-size: 17px;
    color: black;
}

.loginform {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 5%;
}

.innerLogin {
    align-self: center;
    width: 90%;
}

.noaccount {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
}

@media screen and (max-width: 768px) {
    .formContainer{
        flex-direction: column;
    }

    .sideImgContainer {
        width: 100%;
        height: 10%;
    }

    .inner {
        width: 100%;
    }

    .formContainerI {
        flex-direction: column;
    }

    .loginform{
        width: 100%;
        margin-bottom: 3em;
    }
}