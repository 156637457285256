.form-container-in {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .heading-rent-I {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #616161;
    margin: 10px 0;
  }
  
  .heading-rent-II {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 15px;
  }
  
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .columngrid {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1em;
  }
  
  .inputcolumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 30%;
  }
  
  .inputcolumn-65 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 65%;
  }
  
  .input2column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 47.5%;
  }
  
  .input-column-full {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .exclam-but {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #91ea91;
    color: white;
    position: absolute;
    right: 0;
    margin-left: 1em;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
  }
  
  .inputcolumn > span {
    font-weight: 500;
  }
  
  .inputcolumn-65 > span {
    font-weight: 500;
  }
  
  .input-column-full > span {
    font-weight: 500;
  }
  
  .input2column > span {
    font-weight: 500;
  }
  
  .inputfield {
    width: 100%;
    height: 3.5em;
    padding: 1em;
    border: 0.1px solid #4d4d4d;
    border-radius: 0.3em;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #4d4d4d;
  }
  
  .inputfield-drop {
    width: 100%;
    height: 3.5em;
    padding: 1em;
    border: 0.1px solid #4d4d4d;
    border-radius: 0.3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .inputfield-drop-date {
    width: 100%;
    height: 3.5em;
    padding: 0.3em;
    border: 0.1px solid #4d4d4d;
    border-radius: 0.3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .inputfield-drop-date > span {
    padding-left: 1em;
  }
  
  .selectinput {
    width: 100%;
    height: 4em;
    border-radius: 0.5em;
  }
  
  .red {
    color: #e81b33;
    padding-left: 0.2em;
  }
  
  .amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #383838;
    margin: 0.1em
  }
  
  .continue-butt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #91ea91;
    margin: 3em 0;
    align-self: flex-end;
    border-radius: 0.4em;
  }
  
  .continue-butt > span {
    color: white;
    padding: 0.5em;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
  }
  
  .quote-box {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2.5px solid #383838;
    border-radius: 0.4em;
    align-self: center;
    margin: 2em 0;
  }
  
  .quote-box > p {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #383838;
    padding-top: 1em;
    text-align: center;
  }
  
  .quote-box > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #8a8a8a;
    padding: 1em 0.2em;
    padding-bottom: 1em;
    text-align: center;
  }
  
  .container-bottom-butt {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .container-bottom-butt > span {
    color: #91ea91;
    text-decoration: underline;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  
  
  // landlord-details
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 2em;
  }
  
  .headerblock {
    width: 17px;
    height: 19px;
    background-color: rgba(145, 234, 145, 1);
    margin-right: 0.5em;
  }
  
  .headertext {
    font-size: 18px;
    font-weight: 700;
  }
  
  .doc-header{
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #727272;
    margin-top: 0.7em;
    margin-bottom: 2em;
  }
  
  // terms and conditions
  .terms-header{
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #4D4D4D;
  }
  
  .head-terms {
    margin: 4em 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #4D4D4D;
  }
  
  .each-terms-head{
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #91ea91;
    margin-bottom: 0.2em;
  }
  
  .each-term-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
  }
  
  // GetQuoteSuccess
  .get-quote-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .get-quote-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: #383838;
    margin: 1em 0;
  }
  
  .get-quote-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #383838;
    text-align: center;
    margin: 0.7em 0;
  }
  
  .get-quote-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F4F9F0;
    border: 3px solid #383838;
    border-radius: 0.3em;
  }
  
  .get-quote-card-I {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F4F9F0;
    border: 3px solid #383838;
    border-radius: 0.3em;
    min-width: 50%;
  }

  .get-quote-card-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    // padding: 1em;
  }
  
  .get-quote-card-p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #8A8A8A;
    // padding: 0 10em;
  }
  
  .get-quote-card-span {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #91ea91;
    // padding: 0 10em;
  }
  
  .get-quote-card-span-top {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #4D4D4D;
    // padding: 0 10em;
  }
  
  .get-quote-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin-top: 2em;
  }
  
  .get-quote-but-green{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #91ea91;
    color: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    width: 100%;
    margin: 0.4em 0;
    border-radius: 0.3em;
  }
  
  .get-quote-but-white{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #91ea91;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    width: 100%;
    margin: 0.4em 0;
    border: 0.5px solid #91ea91;
    border-radius: 0.3em;
  }
  
  .get-quote-but-green > span {
    padding: 0.5em 0
  }
  .get-quote-but-white > span {
    padding: 0.5em 0
  }

  //home-owners
  .light-text-hm{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #8A8A8A;
    margin-bottom: 2em;
  }

  .light-text-hm-2{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #8A8A8A;
    width: 35%;
    text-align: justify;
    margin-bottom: 2em;
  }

  .light-text-hm-3{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #8A8A8A;
  }

  .second-column-hm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 5em;
  }

  .upload-div-hm{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5em;
    margin-top: 2em;
  }

  .upload-div-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 7%;
  }

  .green-head-hm {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #91ea91;
    padding-bottom: 1em;
  }

  .category-head-hm {
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    color: #383838;
    padding-top: 3em;
    padding-bottom: 1em;
  }

  .category-head-hm-green {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #91ea91;
    padding-bottom: 1em;
  }

  .category-hm{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  // .category-hm-check-cont{
  //   // padding: 2em 0.5em;
  // }

  .category-left{
    border: 1px solid #4D4D4D;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    border-radius: 0.5em;
  }

  .category-left-inner {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 37px 0;
  }

  .category-right {
    border: 1px solid #4D4D4D;
    background-color: #FAFCF8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    border-radius: 0.5em;
  }

  .category-right-inner {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .get-a-quote-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #91ea91;
    background-color: white;
    color: #91ea91;
    align-self: center;
    margin-top: 1.5em;
    border-radius: 0.5em;
  }

  .get-a-quote-button:hover{
    background-color: #91ea91;
    color: white;
  }

  .get-a-quote-button > span {
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    padding: 1em;
  }

  .total-insured-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1em auto;
  }

  .total-insured-value-p{
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #8A8A8A;
    // padding: 1em;
  }

  .total-insured-value-span{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #616161;
    // padding: 1em;
  }

  .active-policy-view{
    width: 70%;
    background-color: #FAFCF8;
    border: 2px solid black;
    border-radius: 0.7em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1.5em auto
  }

  .active-policy-view-a{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .active-policy-view-a > span {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #727272;
    padding: 1em 0;
  }

  .active-policy-view-b{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .active-policy-view-b > span {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    color: #4D4D4D;
    padding: 1em 0;
  }

  .active-policy-view-line  {
    height: 20em;
    width: 1px;
    background-color: #8A8A8A;
  }

  .button-cont-active-pol {
    width: 70%;
    margin: 1em auto;
  }

  .button-cont-active-pol-div {
    background-color: #91ea91;
    border-radius: 0.5em;
    
  }

  .button-cont-active-pol-div > span {
    padding: 0.5em 1em;
    color: white;
  }

  
  @media screen and (max-width: 768px) {
    .quote-box {
      width: 80%;
    }
  
    .columngrid {
      flex-direction: column;
    }
  
    .input2column {
      width: 100%;
      margin-bottom: 1em;
    }
  
    .inputcolumn {
      width: 100%;
      margin-bottom: 1em;
    }
  
    .inputcolumn-65 {
      width: 100%;
      margin-bottom: 1em;
    }

    .get-quote-card-cont{
      margin: 0
    }
  
    .get-quote-buttons{
      width: 75%;
    }
  
    .get-quote-card-span{
      padding: 1em 5em;
    }
  
    .get-quote-card-p{
      padding: 1em 5em;
    }

    .light-text-hm-2{
      width: 100%;
    }

    .upload-div-hm{
      flex-direction: column;
    }

    .upload-div-left{
      margin-right: 0;
      margin-bottom: 1em;
    }

    .category-hm {
      flex-direction: column;
      align-items: center;
    }
  
    .category-left,
    .category-right {
      width: 100%;
      margin-bottom: 1em;
    }
  
    .category-left-inner,
    .category-right-inner {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .category-left-inner {
      padding: 20px 0;
    }
  
    .category-right-inner {
      padding: 20px 0;
    }

    .category-hm-check-cont{
      max-width: 90%;
    }
  }
  