.tableBody tr{
    cursor: pointer;
}

.tableBody tr:hover{
    background-color: #e4e4ed !important;
}

.display{
    background-color: var(--section-bg-1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    width: "100%";
    height: "100%";
    padding: 3%;
    align-items: center;
}

.balanceDisplay{
    color:  var(--ltn__secondary-color);;
}

.balanceDisplay h4{
    margin: 0;
    display: flex;
    font-size: 35px;
}

.balanceDisplay p{
    margin: 0;
    font-size: 14px;
}


@media (max-width: 768px) {
    .balanceDisplay h4{
        font-size: 1.5em;
    }

    .balanceDisplay p {
        font-size: 1.5em;
    }
}