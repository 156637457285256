.del-container {
    width: 65%;
    height: 'auto';
    max-height: 100vh;
    max-width: 80vw;
    border-radius: 0.5em;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 3em auto;
    border-top: 0.3px solid rgb(211, 218, 224);
}

.inner-container {
    width: 90%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2em 0 2em 0;
}

.inner-container > p {
    font-size: 22px;
    font-weight: 400;
}

.inner-container > span {
    font-size: 16px;
    font-weight: 700;
    text-align: justify;
}

#del-text {
    font-size: 16px;
    font-weight: 200;
}

.input-button {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.input-button> input {
    border: 0.5px solid slategray;
    border-radius: 0.5em;
    padding: 2px;
    background-color: white;
    width: 70%;
    height: 100%;
}

.input-button > input:focus{
    border: 0.5px solid #c5a6ad;
}

.input-button > div {
    width: 20%;
    height: 100%;
    background-color: #92223e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 15px;
    font-weight: 400;
    border-radius: 0.5em;
}

.input-button > div:hover{
    font-weight: 600;
    opacity: 0.8;
}


@media screen and (max-width: 768px){
    .input-button{
        flex-direction: column;
        align-items: flex-start;
        height: 110px;
    }
    .input-button > div{
        width: 40%;
        height: 45%;
        min-width: 150px;
    }

    .input-button > input{
        height: 45%;
        width: 100%;
    }

    .inner-container{
        height: 50vh;
    }

    .del-container{
        width: 75%;
    }
}