.backdrop{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.localBackrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}