.container{
    max-width: 1440px;
    margin: auto;
    height: 100%;
    position: relative;
    display: flex;
    padding: 40px 30px;
    margin-bottom: 30px;
    background-color: #f7f8fa;
}

.cardContent{
    display: flex;
}

.productPrice{
    background-color: #dbe0ec;
    padding: 20px;
}

.productPrice span{
    font-weight: 800;
}

.breakdown{
    padding: 20px;
    border-right: 1px solid #e3e2e3;
    flex: 25%
}

.breakdownList{
    margin-top: 20px;
    padding: 0;
}

.breakdownList li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.downPayment{
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    padding: 20px;
    border-right: 1px solid #e3e2e3;
}

.icon{
    margin-bottom: 20px;
}
