.tabContent p{
    font-family: var(--ltn__body-font);
}
.button{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.16, rgb(207, 207, 207)), color-stop(0.79, rgb(252, 252, 252)));
    background-image: -moz-linear-gradient(center bottom, rgb(207, 207, 207) 16%, rgb(252, 252, 252) 79%);
    background-image: linear-gradient(to top, rgb(207, 207, 207) 16%, rgb(252, 252, 252) 79%); 
    padding: 3px;
    border: 1px solid #000;
    color: black;
    text-decoration: none;
}
.btn{
    display: inline-block;
    padding: 5px 12px;
    margin-bottom: 0;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid black;
    text-decoration: none;

    color: #333;
    background-color: rgb(241, 236, 236);
    border-color: #ccc;
}

.imageOverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

.imageOverlay:hover{
    opacity: 1;
}

.image{
    width: 100%;
    height: 100%;
}

.image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.proofDiv{
    padding: 5px;
    margin-bottom: 10px;
    width: 10%;
    height: 10%;
    position: relative;
    display: flex;
    align-items: center;
}




// video player

.videoPlayer {
    width: 25em;
    height: 15em;
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    margin-top: 3em;
    margin-bottom: 5em;
    overflow: hidden;
}

.videoPlayerIn {
    width: 10%;
    height: 10%;
}

@media screen and (max-width: 768px){
    // .videoPlayer{
    //     width: 40%;
    //     height: 3em;
    //     margin-left: auto;
    //     margin-right: auto;
    // }
}