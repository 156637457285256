.imagecard{
display: flex;
// flex-direction: column;
height: 50%;
width: 100%;
margin-top: 20px;
}

.visiontext {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-top: 20px;
}

.secondcard {
    margin-top: 20px;
    margin-right: 40px;
}
.imagecards{
    display: flex;
    // flex-direction: column;
    // height: 20%;
    width: 120%;
    margin-top: 20px;
}

@media (min-width: 600px) and (max-width: 800px) {
    .imagecard{
        display: flex;
        // flex-direction: column;
        height: 100%;
        width: 200%;
        margin-top: 20px;
        }
        
        .visiontext {
            font-size: 16px;
            font-weight: 500;
            color: black;
            margin-top: 20px;
        }
        
        .secondcard {
            margin-top: 20px;
            margin-right: 40px;
        }
        .imagecards{
            display: flex;
            // flex-direction: column;
            height: 120%;
            width: 120%;
            // margin-top: 20px;
        }
  }