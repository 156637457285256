.unavailable{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 993px){
    .container{
        max-width: 960px;
    }
}