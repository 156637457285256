.containercard {
  width: 100%; /* Full width */
  height: 200px; /* Full height */
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-top: 5px solid #ebf4eb;
  background-color: white;
  border-radius: 1em;
  position: relative;
  flex-direction: column;
}

.card-avatar {
  --size: 40px;
  background: linear-gradient(to top, #ebf4eb 0%, #bfe8bf 100%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
  justify-self: flex-start;
  overflow: hidden;
}

.card-avatar img {
  width: 40px;
  height: 40px;
}

.cardpointer {
  width: auto;
  fill: #515f65;
  //   font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: fill 0.2s ease, transform 0.2s ease;
  display: none; /* Hide the "See more" content by default */
  position: absolute;
  bottom: 3px;
  left: auto;
  right: auto;
}

.containercard:hover .cardpointer {
  display: block;
}

.card-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 2rem;
  width: 85%;
  max-width: 90%;
  text-align: center;
}

.card-subtitle {
  color: #859ba8;
  font-size: 0.8em;
  max-width: 90%;
  text-align: center;
}

.indicator-treated {
  width: 75%;
  height: 2px;
  background: linear-gradient(to top, #b5ecb5 0%, #91ea91 100%);
  position: absolute;
  bottom: 0.5px;
}

.indicator-pending {
  width: 75%;
  height: 2px;
  background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%);
  position: absolute;
  bottom: 0.5px;
}
