.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 5em; 
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .griditem {
    border: 1px solid #ccc;
    padding: 5em;
  }

  .overallContainer {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .fonts {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
  }
  
  .fontsTopic {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: large;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .noListing {
    width: 20em;
    height: 12em;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 5em;
    border-top-width: 0.5em;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 255, 255, 0.02);
  }




  @media screen and (max-width: 768px){
    .container{
        display: flex;
        width: 85%;
        flex-direction: column;
        align-items: center;
    }

    .griditem{
        margin-bottom: 2em;
    }
  }