.container{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.image{
    height: 100%;
    display: flex;
    justify-content: center;
}

.image img{
    height: 100%;
    object-fit: cover;
}

.btnGroup{
    position: absolute;
    top: calc(50% - 42px);
    width: 100%;
    display: flex;
    justify-content: space-between;
}