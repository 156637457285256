.containerI {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));  
    gap: 5em; 
    width: 80%;/* Adjust width to fit the screen */
  max-width: 1200px; /* Set a max-width to prevent excessive stretching on large screens */
  margin-left: auto;
  margin-right: auto;
  // overflow-x: hidden; 
  }
  
  // .griditem {
  //   border: 1px solid #ccc;
  //   padding: 1em;
  //   max-width: calc(100% / 3 - 2em);
  //   box-sizing: border-box;
  // }

  // .overallContainer {
  //   flex: 1;
  //   align-items: center;
  //   justify-content: center;
  // }

  .griditem {
    // padding: 1em; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding is included in item width */
    width: 100%; /* Each item takes full width of its grid cell */
    
  }
  
  .overallContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fonts {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
  }
  
  .fontsTopic {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: large;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .noListing {
    width: 20em;
    height: 12em;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 5em;
    border-top-width: 0.5em;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 255, 255, 0.02);
  }




  @media screen and (max-width: 768px){
    .containerI{
        display: flex;
        width: 85%;
        flex-direction: column;
        align-items: center;
    }

    .griditem{
        margin-bottom: 2em;
    }
  }