.list{
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    flex-wrap: wrap;
}

.schedule{
    display: flex;
    flex: 1;
    align-items: flex-start;
}

.date{
    text-align: center;
    flex: 0.3
}

.stepperItem{
    flex: 1;
    display: flex;
    margin: 0;
    margin-left: 0.75rem;
}

.circleArea{
    position: relative;
    display: flex;
}
.circle{
    --size: 1.5rem;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: white;
    position: relative;
    background-color: #e0e0e0;
}

.paid{
    background-color: #00ff44;
}

.failed{
    background-color: #ff2600;
}

// .connector{
//     background-color: #e0e0e0;
//     width: 4px;
//     height: 100%;
//     top: 1.5rem;
//     left: calc(50% - (4px / 2) );
//     position: absolute;
// }

.schedule:not(:last-child) .connector{
    background-color: #e0e0e0;
    width: 4px;
    height: 100%;
    top: 1.5rem;
    left: calc(50% - (4px / 2) );
    position: absolute;
}
// .schedule:not(:last-child) .circle::after{
//     content: "";
//     background-color: #e0e0e0;
//     width: 2px;
//     height: 100%;
//     position: absolute;
//     top: 100%;
//     //z-index: -1;
// }

.stepContent{
    width: 100%;
    margin-left: 0.75rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.stepContent h6{
    margin: 0;
    color: var(--ltn__paragraph-color);
}

.stepContent p{
    margin: 0;
    color: var(--section-bg-2);
}