.container{
    width: 100%;
}

.flexEnd{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.title{
    color: #4D4D4D;
    font-weight: 500;
    font-size: 32px;
}

.categoryView{
    border: 1px solid #8A8A8A;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.infoSection{
    border: 1px solid #8A8A8A;
}

.btnContainer{
    display: flex;
    justify-content: center;
}

.previewBtn{
    background-color: #cce4cc;
    border: 1px solid #cce4cc;
    border-radius: 10px;
    color: #383838;
    font-weight: bold;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    cursor: pointer;
}

.submitBtn{
    background-color: #91EA91;
    border: 1px solid #91EA91;
    border-radius: 10px;
    color: #383838;
    font-weight: bold;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    cursor: pointer;
}

.paperClass {
    z-index: 10000 !important;
}

.modalContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 24px;
    padding: 10px;
}

@media (max-width: 768px) {
    .modalContainer{
        width: 90%;
    }
}