.accordion{
    margin-bottom: 15px;
}

.contentWrapper{
    height: 0;
    overflow: hidden;
    transition: height ease 0.35s, padding ease 0.35s;
    padding: 0 30px;
    border: 0px solid var(--border-color-1); 
}

.cardArea{
    background-color: var(--section-bg-1);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cardLogo{
    display: flex;
    align-items: center;
    flex: 0.7;
}

.cardLogo h6{
    margin: 0;
}

.nameNumber{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    flex: 1;
}

.nameNumber p{
    padding: 0;
    margin: 0;
}

.cardAction{
    
    display: flex;
}

.cardAction i{
    cursor: pointer;
}

.cardAction i:first-child{
    margin-right: 10px;
}

.cardPayment{
    margin-bottom: 50px;
}