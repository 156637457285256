.textInput input{
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    //margin-bottom: 0px;
    border-radius: 0;
    padding-right: 40px;
}

// .textInput input:required{
//     border: 1px solid red;
// }

.textInput label{
    position: relative;
    top: -25px;
    font-size: 14px;
    color: red;
}

.textInput input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

.textInputAmount{
    -moz-appearance: textfield;
    box-sizing: border-box;
    padding-left: 30px !important;
}

.textInput{
    position: relative;
}

.naira{
    position: absolute;
    top: 25%; 
    left: 5px;
    transform: translateY(-25%);
}

.disabled input{
    background-color: rgb(218, 214, 214);
}

.textInput {
    position: relative;
  }
  
  .passwordToggle {
    position: absolute;
    top: 0em;
    left: 30em;
    bottom: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }
  
  .passwordToggle svg {
    width: 16px;
    height: 16px;
  }

  .passwordToggle2 {
    position: absolute;
    top: 0;
    left: 30em;
    bottom: 0em;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 90%;
    cursor: pointer;
    z-index: 1;
    align-items: center;
    height: 40%;
  }

  .passwordToggleLogin {
    position: absolute;
    top: 0;
    left: 32em;
    bottom: 0em;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 90%;
    cursor: pointer;
    z-index: 1;
    align-items: end;
    height: 40%;
  }

  @media (max-width: 768px) {
   
    .passwordToggle {
        position: absolute;
        top: 0;
        left: 17em;
        bottom: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 100%;
        cursor: pointer;
        z-index: 1;
      }

      .passwordToggle2 {
        position: absolute;
        top: 0;
        left: 17em;
        bottom: 0em;
        display: flex;
        justify-content: center;
        width: 32px;
        height: 90%;
        cursor: pointer;
        z-index: 1;
        align-items: center;
        height: 40%;
      }

      .passwordToggleLogin {
        position: absolute;
        top: 0;
        left: 19em;
        bottom: 0em;
        display: flex;
        justify-content: center;
        width: 32px;
        height: 90%;
        cursor: pointer;
        z-index: 1;
        align-items: end;
        height: 40%;
      }
    
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .passwordToggleLogin {
        left: 33em;
      }

      .passwordToggle {
        left: 34em;
      }

      .passwordToggle2 {
        left: 34em;
      }
    }