.container {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    align-items: center;
}

.logoContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 3em;
}

.logoImage{
    height: 60px;
    width: 195px;
    overflow: hidden;
    border-width: 0;
}

.centerDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;
}

.q1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 3em;
}

.q2 {
    margin-top: 3em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 5em;
}

.span {
    font-weight: bold;
    color: #3A3A3A;
}

.button {
    width: 10em;
    height: 2.5em;
    border-radius: 0.5em;
    background-color: #91EA91;
    align-items: center;
    justify-content: center;
    color: #3A3A3A;
    display: flex;
    flex-direction: row;
}

.buttonspan {
    font-size: 12px !important;
    font-weight: bold;
}