.empty-drop-down {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.empty-drop-down-date {
  width: 100%;
  height: 100%;
  z-index: 40;
  background-color: white;
  border: none;
  // visibility: hidden;
  opacity: 0;
}

.input {
  width: 100%;
  height: 100%;
}

.drop-down {
  position: absolute;
  right: 10%;
  top: 50%;
}

.dateinput {
  width: 100%;
  height: 100%;
  border: none
}

.panel {
  background-color: white;
  z-index: 50;
}