.testimonial-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-header {
  height: 64px;
  background-color: rgba(229, 250, 229, 1);
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.test-header-txt {
  font-size: 24px;
  font-weight: 700;
  color: rgba(145, 233, 145, 1);
  padding: 0 40px;
}

.header {
  font-size: 40px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 40px;
  text-align: center;
}

.cards-container {
  width: 70%;
  height: 356px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.card {
  width: 50%;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(246, 253, 246, 1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  padding-left: 5%;
  padding-right: 5%;
}

.card-1 {
  background-color: rgba(246, 253, 246, 1);
}

.card-2 {
  background-color: rgba(252, 240, 238, 1);
}

.card-3 {
  background-color: rgba(254, 250, 235, 1);
}

.card-center {
  position: absolute;
  left: 25%;
  z-index: 99;
}

.card-opaque {
  opacity: 0.8; /* Slight fade-out */
  filter: blur(0.5px); /* Add blur effect */
  // background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  padding: 20px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px); 
}

.card-text {
  font-size: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}

.button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-more {
  background-color: rgba(127, 127, 127, 1);
}

.button-none {
  background-color: rgba(127, 127, 127, 0.5);
}

.button-container {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 100px;
}

.button-text {
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
}

.profile-container {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  margin-top: 20px;
}

.profile-img-con {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow: hidden;
}

.profile-img {
  width: 100%;
  height: 100%;
}

.profile-text {
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: center; 
  margin-left: 10px;
}

.quote-container {
  align-items: center; 
}

.left-quote {
  margin: 0 10px 15px 0
}

.right-quote {
  margin: 15px 0 0 10px
}

@media screen and (max-width: 768px) {
  .cards-container {
    width: 90%;
  }
  .card{
    width: 100%;
  }
  .card-opaque{
    display: none;
  }
  .card-center{
    position: static;
  }
}
