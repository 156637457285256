.imageslider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderbutton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
  }
  
  .sliderimage {
    width: 100%;
    height: 100%;
    // margin: 0 20px;
  }
  