.agentImage img {
  border-radius: 100% !important;
  height: 50px;
  object-fit: cover;
}

.applyButton {
  padding: 0 10px;
  width: auto;
  line-height: 34px;
  color: white;
}

.viewBut {
  background-color: #91ea91;
}

.buttonItem {
  width: auto !important;
  padding: 0;
  margin: 0;
  line-height: 34px;
  background-color: var(--ltn__secondary-color) !important;
}

.image {
  padding-top: 76.5%; //Aspect ratio of 850 : 650
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.imagevid {
  padding-top: 50%; //Aspect ratio of 850 : 650
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.status {
  border-radius: 50%;
}

.status i {
  color: white;
}

.amber {
  background-color: #ffbf00 !important;
}

.green {
  background-color: #4bb543 !important;
}

.realEstate {
  // width: auto;
  // width: 22em;
  // max-width: 23em;
  // padding-left: 1.85em;
  // padding-right: 0.1em;
  width: 40%;
  max-width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #91ea91;
  font-weight: bold;
  display: block;
}

.realEstateDiv {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.completed {
  font-weight: 500;
  color: #91ea91;
  text-transform: uppercase;
}

.saveDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.player {
  width: 500px;
  height: 300px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}



@media screen and (max-width: 768px){
    .realEstateDiv{
        width: 89%;
    }
}
