.input{
    height: 77px !important;
}

.label{
    font-size: 14px;
}

.btn{
    padding: 10px 25px !important;
    font-size: 14px !important;
}

.input1{
    height: 70px !important;
}