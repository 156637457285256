.button{
    background-color: var(--ltn__secondary-color);
    color: var(--white) !important;
    padding: 12px 30px !important;
}

.button:hover{
    background-color: var(--section-bg-1);
    color: var(--ltn__primary-color) !important;
}

.walletdiv{
    border-radius: 8px;
    // padding: 3%;
    margin-top: 5px;
    margin-bottom: 15px
}
.display{
    background-color: var(--section-bg-1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    align-items: center;
}

.balanceDisplay{
    color:  var(--ltn__secondary-color);;
}

.balanceDisplay h4{
    margin: 0;
    display: flex;
    font-size: 35px;
}

.balanceDisplay p{
    margin: 0;
    font-size: 14px;
}

.bottomSection{
    margin-top: 15px;
    display: flex;
    justify-content: end;
}

.bothButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8em
}