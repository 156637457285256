.amountField input{
    background-color: #f4f7fc !important;
}

.amountField{
    padding: 0px;
}

.fundWallet p{
    color: black;
}

.fundWallet p:first-child{
    font-weight: 600;
    margin: 0px;
}

.fundWallet{
    margin-top: 5%;
}

.card{
    margin-bottom: 15px;
}

.source{
    padding: 0 10px;
}

.btn{
    padding: 8px 22px !important;
    font-size: 14px !important;
}

.btnContainer{
   display: flex;
   justify-content: center;
}

.cardDiv{
    max-height: 280px;
    overflow-y: auto;
    margin-bottom: 15px;
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: auto;  /* Internet Explorer 10+ */
}

.selectedPayment{
    border: 2px solid var(--border-color-9);
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.cardLogo{
    display: flex;
    align-items: center;
    flex: 1;
}

.cardLogo img{
    width: 50px;
    max-height: 50px;
}

.nameNumber{
    margin-left: 10%;
}
 
.nameNumber p{
    margin: 0;
}

.bankTransfer > p:first-child{
    color: black;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
}

.bankTransfer p{
    font-size: 14px;
}

.accountDisplay{
    background-color: #FAFAFA;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
}

.accountDisplay p{
    margin: 0px;
}

.debitDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.debitDisplay input{
    text-align: center;
}

.otpLink{
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.otpLink:hover{
    color: var(--ltn__secondary-color)
}