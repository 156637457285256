.description{
    font-size: 14px;
    white-space: normal;
    word-break: break-all;
}

.description textarea{
    float: left;
}

.progress{
   display: flex;
   justify-content: center;
}

.amount{
    height: 100%;
    padding: 30px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.amount h1{
    text-align: center;
    //margin: 0px;
    font-size: 36px;
}

.amount p{
    text-align: center;
    margin: 0px;
    background-color: var(--ltn__secondary-color);
    padding: 0px 8px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
}

.targetAmount p{
    background-color: var(--section-bg-2);
}

.overview{
    margin-top: 5%;
}
.titleSection{
    display: flex;
    justify-content: space-between;
}

.titleSection i{
    margin-right: 3px;
    align-items: center;
}

.title{
    display: flex;
    align-items: center;
}

.title h4{
    margin: 0px;
}

.actions{
    display: flex;
    margin-top: 15px;
}
