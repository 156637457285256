.tab{
    margin-right: 30px;
    font-size: 18px;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    padding: 1px;
    font-weight: 600;
    line-height: 1.2;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    color: var(--ltn__heading-color);
    transition: all 0.3s ease 0s;
    font-family: var(--ltn__body-font);
    -webkit-appearance: button;
    cursor: pointer;
}

.tab::before{
    position: absolute;
    content: "";
    right: 45%;
    top: 100%;
    -webkit-transform: translateY(-40%);
    -ms-transform: translateY(-40%);
    transform: translateY(-40%);
    height: 10px;
    width: 10px;
    background-color: transparent;
    opacity: 1;
    border: 3px solid transparent;
    border-radius: 100%
}

.active{
    color: var(--ltn__secondary-color);
    background-color: transparent;
    border-color: var(--ltn__secondary-color);
}

.active::before{
    background-color: var(--ltn__secondary-color);
    border: 3px solid var(--white);
}


