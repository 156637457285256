.address{
    font-size: 14px;
    text-align: center;
}

.imgContainer {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    // background-color: lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 40%;
    margin-bottom: 1em;
    overflow: hidden;
}
.name {
    width: 70%;
}
.namediv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}
.titlediv {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dummy {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 40%;
    margin-bottom: 1em;
    overflow: hidden;
}

.logoCont{
    width: 12em;
    height: 12em;
    background-color: rgb(154, 178, 154);
    border-radius: 50%;
    margin: 0 auto 0 auto
}