.container {
  width: 100%;
  align-self: center;
  align-items: center;
//   border: 1px rgba(169, 169, 169, 0.5) solid;
  display: flex;
  flex-direction: column;
  margin-top: 0em;
  margin-bottom: 3em;
}

.header {
  width: 100%;
  height: 5em;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.innerHeader {
  width: 40%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.border {
  height: 1px;
  background-color: rgba(169, 169, 169, 0.4);
  width: 85%;
  margin-bottom: 0.5em;
}

.logos {
  height: 6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 0.5em;
}

.form {
  width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
  margin-top: 0em;
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 2em; 
}

.willRSA {
  width: 102%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.5em;
}

.willRSAI {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5em;
  margin-top: 1em;
  align-self: flex-start;
}

.input {
  width: 100%;
  height: 9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputII {
  width: 100%;
  height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputIII {
  width: 100%;
  height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.inputI {
  width: 100%;
  height: 8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.inputTitle {
  width: 100%;
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.inputTitle1 {
  width: 100%;
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.img {
  width: 60%;
}

.submit {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1em;
  // margin-left: 2em;
}

.checkTxt {
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.checkTxtB {
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}

.notice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-color: rgba(0, 255, 255, 0.02);
  background-color: white;
  border-radius: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
}

.noticeButton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.5em;
}

.noticeTxt {
  width: 90%;
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.useAccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.useAccessChild {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.status{
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  margin-left: 1em;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  justify-content: center;
}

.status i{
  color: white;
}

.amber{
  background-color: #FFBF00 !important;
}

.green{
  background-color: #4BB543 !important;
}

.cancelIcon {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 97%;
  margin: 8px auto 3px auto
}

.pop {
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 9em;
  width: 25em;
  bottom: 8em;
  right: 1em;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
}

.pop > span {
  width: 95%;
  font-size: 11px;
  color: black;
  margin-left: 3%;
  margin-bottom: 2%;
  text-align: justify;
}

.pop > ul {
  width: 95%;
  font-size: 11px;
  color: black;
  text-align: justify;
}

.pop > ul > li {
  width: 95%;
  font-size: 11px;
  color: black;
  margin: 0 auto auto 3%;
  text-align: justify;
}

.popI {
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 6em;
  width: 13em;
  bottom: 8em;
  left: 12em;
  border-radius: 1rem 1rem 1rem 0;
  z-index: 7;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  height: 100%;
  align-self: center;
  background-color: white;
}

.modalI {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  height: 85%;
  align-self: center;
  background-color: white;
}

.cancel {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: center;
}

.btn {
  width: 12em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  background-color: #91EA91;
}

.btn1 {
  width: 12em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  background-color: '#FFFFFF';
  border: 1px solid #91EA91
}

.cal {
  width: 100%;
  height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #91EA91;
  margin-bottom: 1.5%;
}

.calTxt {
  padding-left: 5%;
}

.errMsg {
  width: 100%;
  text-align: left;
  color: rgb(230, 65, 65);
  font-size: 12px !important;
  line-height: 14px;
  position: absolute;
  top: 12em
}

.mortgageResDiv {
  background-color: white;
  width: 24em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mortgageResDivPair {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: '2em';
}

.pairL {
  color: black;
  font-weight: 500;
}

.pairR {
  font-weight: bold;
}
// 

@media screen and (max-width: 768px) {
  .mortgageResDiv{
    width: 18em;
    height: 80%;
  }
  .pop {
    left: 0em;
    bottom: 12em;
    width: 22em;
    height: 10em;
  }

  .willRSA{
    margin-bottom: 0.7em;
  }

  .willRSAI{
    margin-bottom: 0.7em;
  }

  .popI {
    bottom: 9em;
    left: 2em;
  }

  .input {
    height: auto;
  }

  .inputI {
    height: auto;
  }

  .inputTitle {
    height: 6em;
  }

  .willRSA {
    align-items: flex-start;
  }

  .checkTxt {
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .modal {
    width: 100%;
  }
}
