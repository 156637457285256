.card{
    display: flex;
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.cardLeft{
    display: flex;
}

.cardLeft p{
    margin: 0px;
    font-size: 11px;
    color: black;
}

.cardLeft p:first-child{
    font-weight: 600;
    font-size: 13px;
}

.cardTitle{
    margin-left: 10px;
}

.arrow i{
    color: var(--ltn__secondary-color);
    font-size: 20px;
}