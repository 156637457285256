.utilCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    border: 1px solid var(--border-color-1);
    cursor: pointer;
}

.utilCard:hover{
    background-color: rgba($color: #000000, $alpha: 0.05);
}

.utilCard p{
    margin: 0;
}

.selected{
    background-color: rgba($color: #000000, $alpha: 0.05);
}